<ion-app>
  <!-- Side Menu -->
  <!--<ion-split-pane contentId="main-content" when="lg">-->
    <ion-menu menuId="side-menu" class="menu-outer" contentId="main-content" type="reveal">
      <ion-content class="content-outer ion-text-center" scrollbar>
        <ion-row class="ion-text-center">
          <ion-col>

            
            <div class="store-name">
              {{ _utilService.LOGIN_INFO?.userStoreDetails?.name }}
            </div>

            <!--<div class="user-name">{{ _utilService.LOGIN_INFO?.userName }}</div>-->
            <ion-chip color="light" mode="ios" (click)="onShowUserActions($event)">
              <ion-label class="user-label">{{ _utilService.LOGIN_INFO?.userName }}</ion-label>
              <span class="profile-select-icon" title="Select">
                  <ion-icon name="caret-down-outline" color="light"></ion-icon>
              </span>
            </ion-chip>

            <ion-popover #popover trigger="left-start" size="auto" [isOpen]="showUserActions" (didDismiss)="showUserActions = false">
              <ng-template>
                <ion-list>
                  <ion-item [button]="true" [detail]="true" (click)="onUserActionClick('profile')">
                    <ion-label>Profile</ion-label>
                    <ion-icon name="person-outline" size="small" slot="start"></ion-icon>
                  </ion-item>
                  <ion-item [button]="true" [detail]="true" lines="none" (click)="onUserActionClick('logout')">
                    <ion-label>Logout</ion-label>
                    <ion-icon name="log-out-outline" size="small" slot="start"></ion-icon>
                  </ion-item>
                </ion-list>
              </ng-template>
            </ion-popover>
          </ion-col>
        </ion-row>

        <ion-list id="inbox-list">
          
          <!-- NEW MENU WITH SUB MENU -->
          <div *ngFor="let menuItem of _utilService.LOGIN_INFO?.menu">

            <ion-menu-toggle auto-hide="false"> 
              @if(menuItem.subMenu.length == 0) { <!-- Link without submenu -->
                @if(menuItem?.menuUrlId) { <!-- With route id -->
                  <ion-item class="menu-outer" routerDirection="root" [routerLink]="[menuItem?.menuUrl, menuItem?.menuUrlId]" lines="full" detail="false" [class.active-item]="selectedPath === menuItem?.menuUrl">
                    <ion-icon [name]="menuItem?.menuIcon" slot="start" color="light"></ion-icon>
                    <ion-label color="light">{{ menuItem?.menuLabel }}</ion-label>
                  </ion-item>
                } @else { <!-- Without route id -->
                  <ion-item class="menu-outer" routerDirection="root" [routerLink]="menuItem?.menuUrl" lines="full" detail="false" [class.active-item]="selectedPath === menuItem?.menuUrl">
                    <ion-icon [name]="menuItem?.menuIcon" slot="start" color="light"></ion-icon>
                    <ion-label color="light">{{ menuItem?.menuLabel }}</ion-label>
                  </ion-item>
                }
              }
            </ion-menu-toggle>

            <ion-item class="menu-outer" lines="full" detail="false" (click)="toggleSubMenu(menuItem)" *ngIf="menuItem.subMenu.length > 0">
              <ion-icon [name]="menuItem?.menuIcon" slot="start" color="light"></ion-icon>
              <ion-label color="light">{{ menuItem?.menuLabel }}</ion-label>
              <ion-icon name="chevron-down" slot="end" color="light" *ngIf="!menuItem.showSubMenu"></ion-icon>
              <ion-icon name="chevron-up" slot="end" color="light" *ngIf="menuItem.showSubMenu"></ion-icon>
            </ion-item>

            <ion-menu-toggle auto-hide="false"  *ngIf="menuItem.showSubMenu == 1">
              @for (subMenuItem of menuItem.subMenu; track subMenuItem.menuId) {
                @if(subMenuItem?.menuUrlId) { <!-- With route id -->
                  <ion-item class="menu-outer" routerDirection="root" [routerLink]="[subMenuItem?.menuUrl, subMenuItem?.menuUrlId]" lines="full" detail="false" [class.active-item]="selectedPath === subMenuItem?.menuUrl">
                    <ion-icon class="sub-menu-icon" [name]="subMenuItem?.menuIcon" slot="start" color="light"></ion-icon>
                    <ion-label class="sub-menu-label" color="light">{{ subMenuItem?.menuLabel }}</ion-label>
                  </ion-item>
                } @else { <!-- Without route id -->
                  <ion-item class="menu-outer" routerDirection="root" [routerLink]="subMenuItem?.menuUrl" lines="full" detail="false" [class.active-item]="selectedPath === subMenuItem?.menuUrl">
                    <ion-icon class="sub-menu-icon" [name]="subMenuItem?.menuIcon" slot="start" color="light"></ion-icon>
                    <ion-label class="sub-menu-label" color="light">{{ subMenuItem?.menuLabel }}</ion-label>
                  </ion-item>
                }
              }
            </ion-menu-toggle>
           
          </div>
          
        </ion-list>

        <ion-chip class="ion-margin-top" color="light" (click)="installApp()" *ngIf="appInstallPromptEvent">
          <ion-icon name="cloud-download-outline" color="light"></ion-icon>
          <ion-label color="light">Install App</ion-label>
        </ion-chip>
      </ion-content>
    </ion-menu>

    <!-- Global home button-->
    <ion-fab slot="fixed" vertical="bottom" horizontal="end" [edge]="true" *ngIf="selectedPath && selectedPath !='/login' && selectedPath !='/home' && selectedPath !='/dashboard'">
      <ion-fab-button color="warning" size="small" (click)="onHomeClick()">
        <ion-icon name="home" color="light"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    
    <ion-router-outlet id="main-content"></ion-router-outlet>
  <!--</ion-split-pane>-->
</ion-app>


