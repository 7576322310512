import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';

import { Network } from '@capacitor/network';
import { UtilService } from './../../services/util.service';
import { LoginService } from './../../services/login.service';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.page.html',
  styleUrls: ['./offline.page.scss'],
})
export class OfflinePage implements OnInit {

  constructor(
    public _menuController: MenuController,
    private _modalController: ModalController,
    public _utilService: UtilService,
    private _loginService: LoginService
  ) { }

  ngOnInit() {
  }

  // Actions before showing page
  ionViewWillEnter() {
    // Hide menu
    this._menuController.enable(false);
  }

  // On refresh
  async onRefresh() {
    let networkStatus = await Network.getStatus();
    if (networkStatus.connected == true) { // Online
      //this._loginService.logout(false);
      
      // Close modal
      const onClosedData = { action: 'close' };
      this._modalController.dismiss(onClosedData);
    }
  }

}
