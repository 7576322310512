import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of, from } from 'rxjs';
import { retry, catchError, map, filter, take, switchMap, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { UtilService } from './util.service';
import { LoginService } from './login.service';


@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _utilService: UtilService,
    private _loginService: LoginService
  ) { }

  /*intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this._utilService.checkInternetConnection()) {
      this._router.navigate(['no-connection']);
      return throwError('no_connection');
    } else {
      console.log('HttpErrorInterceptor')
      return next.handle(req);
    }
  }*/

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    /*let deviceId = 'xxx';
    req = req.clone({
      setParams:{
        deviceId: deviceId
      }                          
    })*/
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) { // Unauthorized toke error
          this._utilService.showToastMessage(response.error.message, 6000, 'warning');
          this._loginService.removeLocalData(true);
        } else {
          this._utilService.showToastMessage(environment.serverErrorMessage, 3000, 'danger');
        }

        return throwError(response);
      })
    );
  }



  

}