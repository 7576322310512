import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { tap } from 'rxjs/operators'

import { UtilService } from './../services/util.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {

  token = null;

  constructor(
    private _angularFireMessaging: AngularFireMessaging,
    public _utilService: UtilService,
  ) {}

  requestPermission() {
    return this._angularFireMessaging.requestToken.pipe(
      tap(token => {
        this._utilService.FIREBASE_MESSAGING_TOKEN = token;
        //this._utilService.showToastMessage(token, 3000, 'success');
      })
    );
  }

  getMessages() {
    return this._angularFireMessaging.messages;
  }

  deleteToken() {
    if (this.token) {
      this._angularFireMessaging.deleteToken(this.token);
      this.token = null;
    }
  }


}
