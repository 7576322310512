import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ToastController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private _router: Router,
    private _storage: Storage,
    private _platform: Platform,
    public _toastController: ToastController,
    private _httpClient: HttpClient,
    private _utilService: UtilService
  ) {}

  // Login
  login(body: any) {
    const URL = environment.apiUrl + 'api/business/login';

    let reqHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json');

    let encryptedBody = this._utilService.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders});
  }

  // Resend OTP
  resendOTP(body: any) {
    const URL = environment.apiUrl + 'api/business/resendOTP';

    let reqHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json');

    let encryptedBody = this._utilService.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders});
  }

  // Forgot Password
  forgotPassword(body: any) {
    const URL = environment.apiUrl + 'api/business/forgotPassword';

    let reqHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json');

    let encryptedBody = this._utilService.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders});
  }

  // Change Password
  changePassword(loginToken: string, userId: any, storeId: any, body: any) {
    const URL = environment.apiUrl + 'api/business/changePassword';
    
    let reqHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('x-access-token', loginToken)
    .set('x-device-id', this._utilService.LOGIN_INFO.deviceId);

    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId);

    let encryptedBody = this._utilService.encryptData(body);
    let reqBody = JSON.stringify({ data: encryptedBody});

    return this._httpClient.post<any>(URL, reqBody, {headers: reqHeaders, params: reqParams});
  }

  // Logout -----------------
  logoutUser(loginToken: string, userId: any, storeId: any, forceLogout: boolean) {
    const URL = environment.apiUrl + 'api/business/logout';

    let reqHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', loginToken)
      .set('x-device-id', this._utilService.LOGIN_INFO.deviceId);
      
    let reqParams = new HttpParams()
      .set('userId', userId) 
      .set('storeId', storeId)
      .set('forceLogout', forceLogout);
    return this._httpClient.get<any>(URL, {headers: reqHeaders, params: reqParams});
  }
  
  // Logout -----------------
  async logout(forceLogout: boolean) {
    this.logoutUser(this._utilService.LOGIN_INFO.token, this._utilService.LOGIN_INFO.userId, this._utilService.LOGIN_INFO.userStoreDetails.storeId, forceLogout).subscribe(async logoutUserResponse => {
      if (logoutUserResponse) {
        if (logoutUserResponse.status === true) { // Success
          this.removeLocalData(true);
        } else {
          this._utilService.showToastMessage(logoutUserResponse.message, 3000, 'danger');
          this.removeLocalData(true);
        }
      } else {
        this._utilService.showToastMessage(environment.apiErrorMessage, 3000, 'danger');
        this.removeLocalData(true);
      }
    }, error => {
      this.removeLocalData(true);
    });
  }

  // Remove local data -----------------
  async removeLocalData(navigateToLogin: boolean) {
    // Remove user data from storage
    await this._utilService.removeStorageData('SELLER_INFO');
    await this._utilService.removeStorageData('BUYER_INFO');
    await this._utilService.removeStorageData('LOGIN_INFO');
    await this._utilService.removeStorageData('ROUTES');
    await this._utilService.removeStorageData('DN_TEMP');
    await this._utilService.removeStorageData('INV_TEMP');
    await this._utilService.removeStorageData('VS_TEMP');
    await this._utilService.removeStorageData('SIGNUP_TEMP');

    if (navigateToLogin) {
      this._router.navigate(['login']);
    }
  }

}
