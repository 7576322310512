import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Platform, AlertController, MenuController, ModalController } from '@ionic/angular';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PluginListenerHandle } from '@capacitor/core';
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
import { Network } from '@capacitor/network';

import { environment } from '../environments/environment';
import { UtilService } from './services/util.service';
import { LoginService } from './services/login.service';
import { FirebaseMessagingService } from './services/firebase-messaging.service';
import { OfflinePage } from './pages/offline/offline.page';

// For Swiper.js
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChild('popover') popover: any;
  showUserActions = false;

  public appInstallPromptEvent: any;
  @HostListener('window:beforeinstallprompt', ['$event'])

  networkListener?: PluginListenerHandle;

  public selectedPath: any = '';

  private backButtonSubject : Subject<void> = new Subject<void>();

  constructor(
    private _swUpdate: SwUpdate,
    private _platform: Platform,
    private _activatedRoute: ActivatedRoute,
    private _alertController: AlertController,
    private _menuController: MenuController,
    private _modalController: ModalController,
    private _storage: Storage,
    private _router: Router,
    public _utilService: UtilService,
    private _loginService: LoginService,
    private _firebaseMessagingService: FirebaseMessagingService
  ) {
    this.initializeApp();

    this._router.events.subscribe((event) => {
      if (event instanceof RouterEvent) {
        if (event.url) {
          this.selectedPath = event.url;
        }
      }
    });
  }

  ngOnInit() {
    // To disable back button click
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.backButtonSubject)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  initializeApp(): void {
    this._platform.ready().then(async () => {
      // Initialize Storage
      await this._storage.defineDriver(CordovaSQLiteDriver);
      await this._storage.create();

      // Display menu
      this.displayMenu();

      // Check update
      /*if (this._swUpdate.available) {
        this._swUpdate.available.subscribe(() => {
          if (confirm('A new version is available. Load it?'))
            window.location.reload();
        });
      }*/

      if (this._swUpdate.isEnabled) { // CHANGED FOR ANGULAR 17
        this._swUpdate.versionUpdates
          .pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY')) 
          .subscribe(event => {
            if (confirm('A new version is available. Would you like to update?')) {
              window.location.reload();
            }
          });
      }

      // Add network status listner
      this.networkListener = await Network.addListener('networkStatusChange', (status) => {
        if (status.connected == false) { // Offline
          //this._router.navigate(['offline']);
          this.showOffline();
        }
      });

      // Get firebase token for push notification
      this.getFirebaseToken();

      // Listen for firebase push notification
      this.listenForFirebaseMessages(); 

      // Check for app install prompt
      window.addEventListener('beforeinstallprompt', (e) => {
        this.appInstallPromptEvent = e;
        //this._utilService.showToastMessage('This is a Progressive Web App. You can install it on your device.', 3000, 'warning');

        // For login page, show download prompt
        if(this.selectedPath == '/login') {
          this.promptInstall();
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.networkListener) {
      this.networkListener.remove();
    }

    this.backButtonSubject.next();
    this.backButtonSubject.complete();
  }

  // Get Firebase token
  getFirebaseToken() {
    this._firebaseMessagingService.requestPermission().subscribe(async token => {
        //this._utilService.showToastMessage(String(token), 3000, 'warning');
      },async (err) => {
        console.log(JSON.stringify(err));
        //this._utilService.showToastMessage('Error while getting token - ' + JSON.stringify(err), 3000, 'danger');
      }
    );
  }

  // Listen for Firebase messages
  listenForFirebaseMessages() {
    this._firebaseMessagingService.getMessages().subscribe(async (msg: any) => {
      const alert = await this._alertController.create({
        mode: 'ios',
        backdropDismiss: false,
        header: msg.notification.title,
        message: msg.notification.body,
        buttons: ['OK'],
      });

      await alert.present();
    });
  }

  // On show user actions
  onShowUserActions(e: Event) {
    this.popover.event = e;
    this.showUserActions = true;
  }

  // On user action click
  onUserActionClick(page: string) {
    this.showUserActions = false;
    this._menuController.close();

    if (page == 'profile') {
      this._router.navigate(['profile']);
    } else if (page == 'logout') {
      this.onLogout();
    }
  }

  // Display menu
  async displayMenu() {
    if (this._utilService.LOGIN_INFO.length == 0) { // Menu not loaded from storage
      this._utilService.LOGIN_INFO = await this._utilService.getStorageData('LOGIN_INFO', true);
    }
  }

  // On logout
  onLogout() {
    this._loginService.logout(false);
  }

  // Prompt install
  async promptInstall() {
    const alert = await this._alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Install Basket4Me app?',
      message: 'We recommend installing the app for better user experience.',
      buttons: [
        {
          text: 'Not now',
          role: 'cancel',
          handler: () => {}
        }, {
          text: 'Yes, install',
          handler: () => {
            this.installApp();
          }
        }
      ]
    });

    await alert.present();
  }

  // App install 
  async installApp(){
    try {
      if (this.appInstallPromptEvent) {
        const result = await this.appInstallPromptEvent.prompt();
        this.appInstallPromptEvent = null;

        /*this.appInstallPromptEvent.prompt();
        const { outcome } = await this.appInstallPromptEvent.userChoice;
        if (outcome === 'accepted') {
          this.appInstallPromptEvent = null;
        }*/
      }
    } catch(err) {
      this._utilService.showToastMessage(JSON.stringify(err), 3000, 'err');
    }
  }

  // Toggle sub menu
  toggleSubMenu(menuItem: any) {
    for(let i=0; i<= this._utilService.LOGIN_INFO.menu.length-1; i++){
      if (this._utilService.LOGIN_INFO.menu[i].menuId == menuItem.menuId) { // Selected menu
        this._utilService.LOGIN_INFO.menu[i].showSubMenu = !this._utilService.LOGIN_INFO.menu[i].showSubMenu;
      } else {
        this._utilService.LOGIN_INFO.menu[i].showSubMenu = 0;
      }
    }
  }

  // Hide menu
  async hideMenu() {
    this._menuController.close('side-menu');
  }

  // Show offline modal
  async showOffline() {
    const modal = await this._modalController.create({
      component: OfflinePage,
      cssClass: 'modal-w100-h50',
      mode: 'ios',
      backdropDismiss: false,
      componentProps: {}
    });

    modal.onDidDismiss().then(async (modalResponse) => { // On close
    });

    await modal.present();
  }

  // On home click
  onHomeClick() {
    this._router.navigate(['dashboard']);
  }

}
