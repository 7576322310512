import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AuthGuardService } from './services/auth-guard.service';
import { HttpErrorInterceptor } from './services/http-error.interceptor';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPaginationModule } from 'ngx-pagination';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: 'basket4me-pwa-b2b-db',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage] // 'sqlite', 'indexeddb', 'websql'
    }),
    // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(), //environment.production.
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    LoggerModule.forRoot({
      //serverLoggingUrl: environment.ngxLogger.apiUrl,
      //serverLogLevel: environment.ngxLogger.serverLogLevel,
      level: environment.ngxLogger.logLevel,
      disableConsoleLogging:  environment.ngxLogger.disableConsoleLogging
    }),
    NgxCsvParserModule,
    GuiGridModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    ZXingScannerModule,
    BackButtonDisableModule.forRoot()
  ],
  providers: [
    AuthGuardService,
    DatePipe,
    CurrencyPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
