<ion-content>
  <ion-grid class="grid-outer">
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <ion-img class="img" src="assets/images/offline.png"></ion-img>
      </ion-col>
    </ion-row>

    <ion-row class="ion-margin-top">
      <ion-col size="12" class="ion-text-center">
        <ion-label class="title">You appear to be offline!</ion-label>
        <ion-label class="sub-title">Please check your internet conection and try again.</ion-label>
      </ion-col>
    </ion-row>

    <!--<ion-row class="ion-margin-top">
      <ion-col size="4" offset="4" class="ion-text-center">
        <ion-button expand="block" shape="round" color="primary" (click)="onRefresh()">Retry</ion-button>
      </ion-col>
    </ion-row>-->
  </ion-grid>
</ion-content>

<ion-footer mode="ios" class="ion-no-border">
  <ion-toolbar color="light">
    <ion-row class="ion-no-padding">
      <ion-col sizeSm="12" sizeMd="4" offsetMd="4" class="ion-text-center">
        <ion-button expand="block" shape="round" color="primary" (click)="onRefresh()">Retry</ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
