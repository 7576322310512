import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import * as _ from 'lodash';
import { Storage } from '@ionic/storage';

import { UtilService } from './../services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private _storage: Storage,
    private _utilService: UtilService,
  ) { }

  canActivate(
    _activatedRouteSnapshot: ActivatedRouteSnapshot,
    _routerStateSnapshot: RouterStateSnapshot
  ): any { 
    const menuUrlId = _activatedRouteSnapshot.paramMap.get('id'); // Get the id from the route parameters

    const routeUrl = _routerStateSnapshot.url;
    const menuCode = _activatedRouteSnapshot.data['menuCode'];
    const menuParentCode = _activatedRouteSnapshot.data['menuParentCode'];
    const checkMenuUrlId = _activatedRouteSnapshot.data['checkMenuUrlId'];

    return this.isAuthenticated(menuCode, menuParentCode, checkMenuUrlId, menuUrlId);
  }

  // Check login
  isAuthenticated(menuCode: any, menuParentCode: any, checkMenuUrlId: any, menuUrlId: any) {
    return new Promise(async resolve => {
      try {
        this._storage.get('LOGIN_INFO').then(async (response) => {
          if (response) { // Logged in
            // Decrypt login info data
            let decryptedLoginInfoData: any = this._utilService.decryptData(response);

            if (menuCode) {  // Menu code check
              let responseCheckUserAccess = await this.checkUserAccess(menuCode, menuParentCode, checkMenuUrlId, menuUrlId, decryptedLoginInfoData.menu, false);

              if (responseCheckUserAccess) {
                resolve(true);
              } else { // Access denied
                resolve(false);
              }
            } else { // No menu code check
              resolve(true);
            }
          } else { // Not logged in
            resolve(false);
          }
        });
      } catch (error) {
        resolve(false);
      }
    });
  }

  // Check user access -----------------
  checkUserAccess(menuCode: any, menuParentCode: any, checkMenuUrlId: any, menuUrlId: any, menuList: any, checkPubishAccess: any) {
    return new Promise(async resolve => {
      try {
        if (menuCode.length === 0) { // Menu code is missing
          resolve(false);
        } else {
          let menuStatus = [];

          if (!menuParentCode) { // Level 1 menu
            menuStatus = _.filter(menuList, item => item.menuCode == menuCode && item.viewAccess == 1);

            if(checkMenuUrlId == true) { // Check menuParentCode & menuUrlId
              let menuUrlIdStatus = _.filter(menuList, item => item.menuParentCode == menuParentCode && item.menuUrlId == menuUrlId && item.viewAccess == 1);

              if (menuUrlIdStatus.length == 0) {
                resolve(false); // No menu url id access
              }
            }
          } else { // Level 2 menu
            let mainMenu = _.filter(menuList, item => item.menuCode == menuParentCode);
            if (mainMenu.length > 0) { 
              menuStatus = _.filter(mainMenu[0].subMenu, item => item.menuCode == menuCode && item.viewAccess == 1);

              if(checkMenuUrlId == true) { // Check menuParentCode & menuUrlId
                let menuUrlIdStatus = _.filter(mainMenu[0].subMenu, item => item.menuParentCode == menuParentCode && item.menuUrlId == menuUrlId && item.viewAccess == 1);

                if (menuUrlIdStatus.length == 0) {
                  resolve(false); // No menu url id access
                }
              }
            } else {
              resolve(false); // No sub menu access
            }
          }
          
          if (menuStatus.length === 0) { // Menu access not found
            resolve(false); // No menu access
          } else { // Menu access avilable
            if (checkPubishAccess === true) { // Check publish access
              if (menuStatus[0].publishAccess === 1) {
                resolve(true); // Publish access available
              } else {
                resolve(false); // No publish access
              }
            } else { // Check view access
              if (menuStatus[0].viewAccess === 1) {
                resolve(true); // View access available
              } else {
                resolve(false); // No view access
              }
            }
          }
        }
      } catch (error) {
        resolve(false);
      }
    });
  }


}
